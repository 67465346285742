import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Mapster WP Maps <u>Pro</u>.</h2>
        <p className="mb-8">
          The plugin has grown a lot lately. As a result, things are getting complicated in the interface and we want to make sure you don't have to deal with a ton of confusing options.
        </p>
        <a href="https://checkout.freemius.com/mode/dialog/plugin/10260/plan/17314/?show_monthly_switch=true" className="btn-sm text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Click here to purchase Pro!</a><br />
        <p className="mb-8">
          So, we've started to move more complex features and functionality into a "Pro" plugin! This is currently still free, but one day we will probably charge a recurring fee for it. For now, enjoy it!
        </p>
        <h3 id="installation" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Installation</h3>
        <p className="mb-8">
          You'll have to purchase pro -- just click the link above, or go to <a className="text-blue-500" href="https://wpmaps.mapster.me/pro">the pro page</a> and hit Download to buy. Once you do that, install the new plugin and deactivate the old Mapster WP Maps plugin. You'll get automatic updates from then on!
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation">
          <div>
            <div className="text-normal font-medium mb-1">Basic Mapster WP Maps</div>
            <div className="text-sm text-gray-600">Documentation for the non-Pro version of the plugin.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
