import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Advanced Popups</h2>
        <p className="mb-8">
          Popups are crucial to many maps, and it can be annoying to manually keep track of the content of all popups. Pro allows more popup options, such as modals and dynamic popup content by using feature properties and ACF fields.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro,</strong> new syntax will now be possible in your popups immediately.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Fr8-G90ZsEI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="popup-modal" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Popup to Modal</h3>
        <p className="mb-8">
          If you have a ton of content to show inside your popup and it starts to get too big for your map, moving that content to a modal is a great idea.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/special-modal-popup.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          Go to your Location, Line, or Polygon (or use <a className="text-blue-500" href="http://localhost:3000/documentation/mass-editing">Mass Edit</a> to do it for many popups at once) and enable "To Modal" in the options for Button Action. Make sure your popup template has a Button available in it, otherwise the button won't appear.
        </p>
        <p className="mb-8">
          A new text field will appear. You can fill in your heavier details here, and you can use the same advanced syntax described below to populate this modal with dynamic properties from your post or feature data.
        </p>
        <h3 id="advanced-syntax" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Advanced Syntax</h3>
        <p className="mb-8">
          First, you'll need to make sure your features actually have the data. You can see properties from geographic data in any given feature under "Custom Properties", and ACF fields will show up in the appropriate post types.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/custom-properties.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          If you have a ton of content to show inside your popup and it starts to get too big for your map, moving that content to a modal is a great idea.
        </p>
        <p className="mb-8">
          Head to any post where you want to create an advanced popup (or use the Mass Edit to change multiple popups at once). You'll use some special syntax to tell the Category Filter which properties to include under which headers. The syntax looks like this:
        </p>
        <pre className="text-sm sm:text-base mb-4 text-black-600 " style={{background: '#eee', padding: '0px 20px'}} dangerouslySetInnerHTML={{__html : `
  Here's some popup content showing the {property_name}
          `}} />
        <p className="mb-8">
          Or, for ACF fields:
        </p>
        <pre className="text-sm sm:text-base mb-4 text-black-600 " style={{background: '#eee', padding: '0px 20px'}} dangerouslySetInnerHTML={{__html : `
  Here's some popup content showing the {acf.field_r4nd0m5tr1ng}
          `}} />
        <p className="mb-8">
          If you are using an ACF field, enter "acf." and then the field id (shown below).
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/acf-field-keys.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          You can use this in the popup header, the content, or the button text. The curly braced part will be replaced with the relevant property value or ACF value from that specific feature.
        </p>
        <h3 id="custom-html" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Custom HTML</h3>
        <p className="mb-8">
          If you need to totally revamp the popup HTML, you can do it here. In the Popup Template area when creating a template, click the HTML tab.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/custom-html.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          Enter in your HTML. Important to note is that, by using the tags indicated in the description of the field, you can enter in tags that will be replaced in the front end with data of that feature post. These include the Header Text {"{header}"}, Image URL {"{image_url}"}, Content {"{content}"}, Button Link {"{button_url}"}, and Button Text {"{button_text}"}.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/popups">
          <div>
            <div className="text-normal font-medium mb-1">Mapster Popup Documentation</div>
            <div className="text-sm text-gray-600">Learn more about editing basic popups and popup templates.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/advanced-category-filter">
          <div>
            <div className="text-normal font-medium mb-1">Advanced Category Filters</div>
            <div className="text-sm text-gray-600">Advanced Category Filters use custom syntax to add more options and filterable properties.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
