import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Mass Edit</h2>
        <p className="mb-8">
          Mass Editing adds a ton of power and quality of life to Mapster WP Maps by allowing you to make changes en masse to selected features.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro, a new menu item will appear under Maps called "Mass Edit"</strong>. That's where the magic happens!
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/cu5leo6jV98" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="edit-screen" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Edit Screen</h3>
        <p className="mb-8">
          Select features as a group by choosing their categories, or individually using the Locations, Lines, and Polygons selectors. It won't matter if you select the same feature twice.
        </p>
        <p className="mb-8">
          Based on what you select, you'll want to edit Points, Lines, Polygons, and/or Popups. You can edit any or all of these at the same time.
        </p>
        <p className="mb-8">
          Options should be familiar and look the same as they do on the main edit screen for each feature type. Just press the <strong>Edit This Data</strong> checkbox to tell Mapster WP Maps that you want that particular data to be changed during the mass edit.
        </p>
        <p className="mb-8">
          Edit as usual, then press the Mass Edit button at the bottom, and you'll be told how many features got updated.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/categories">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Categories</div>
            <div className="text-sm text-gray-600">Learn more about creating categories in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/advanced-import">
          <div>
            <div className="text-normal font-medium mb-1">Importing in Mapster WP Maps Pro</div>
            <div className="text-sm text-gray-600">You may want to use the advanced importer instead of mass editing.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
