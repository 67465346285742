import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Geography Types</h2>
        <p className="mb-8">
          In Mapster WP Maps, we classify geography types in three ways: Locations, Lines, and Polygons.
        </p>
        <p className="mb-8">
          All features are set in Wordpress as different custom post types, so they can be fully SEO-compatible (if you wish) and can live as their own posts. You can also reuse them across multiple maps, so you don't have to create any shapes twice.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/YBpRlvrRm4U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="add-edit" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Add / Edit / Delete</h3>
        <p className="mb-8">
          Adding Locations, Lines, or Polygons is done by simply clicking on their menu item and pressing "Add New" on the post listing screen. Inside each geography post type, you'll find a plethora of options for how to customize and modify each type of geography.
        </p>
        <p className="mb-8">
          You can easily Duplicate any created Location, Line, Polygon, Map, or Popup Template by hovering over the post in its list, and clicking the "Duplicate" link.
        </p>
        <p className="mb-8">
          For every type of Geography Type, you can create it and edit it by drawing directly, or by uploading a pre-created geoJSON for that geography type.
        </p>
        <p className="mb-8">
          If you assign a shape a category, you can add shapes en masse to a map using that category, too.
        </p>
        <p className="mb-8">
          In the following sections, we'll go over the options associated with each geography type.
        </p>
        <h3 id="locations" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Locations</h3>
        <p className="mb-8">
          "Locations" refers to anything that has a <em>single geographic coordinate</em>. In the GeoJSON specification, this is a type "Point".
        </p>
        <p className="mb-8">
          In the WP Maps plugin, locations can be:
        </p>
        <ul className="list-disc list-inside mb-8">
          <li className="mb-1">Circles</li>
          <li className="mb-1">Markers</li>
          <li className="mb-1">Label & Icon</li>
        </ul>
        <h4 className="h4 text-gray-900 mb-4" >Markers</h4>
        <p className="mb-8">
          Markers are the default display of a location post type. Markers are displayed using a traditional "map markers" icon, and are HTML elements. Markers have a <strong>scale</strong>, <strong>color</strong>, customizable <strong>anchor</strong>, and <strong>rotation</strong>.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/marker-options.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>To set a custom image as your marker, use the Icon & Label Location type instead of the marker type (this is because Mapbox and Maplibre don't generally play very nicely with custom icons for traditional markers).</span>
        </p>
        <h4 className="h4 text-gray-900 mb-4">Circles</h4>
        <p className="mb-8">
          Circles are another way to display a single location. Circles have a <strong>radius</strong>, <strong>opacity</strong>, and <strong>color</strong>, as well as a <strong>border radius</strong>, <strong>border color</strong>, and <strong>border opacity</strong>.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/circle-options.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h4 className="h4 text-gray-900 mb-4" >Label & Icon</h4>
        <p className="mb-8">
          This is the most complex type of Location geography. You can set an icon and options, a text and options, or a combination of both.
        </p>
        <p className="mb-8">
          <strong>Labels</strong> allow you to write directly on your map. By turning Label on, you can customize <strong>text content</strong>, choose from amongst a few <strong>fonts</strong>, set font <strong>size</strong>, <strong>color</strong>, <strong>opacity</strong>, and <strong>rotation</strong>. You can also designate <strong>outline color</strong>, <strong>outline opacity (blur)</strong>, and <strong>outline width</strong>. Finally, you can specify an offset from the location point by specifying a <strong>position x</strong> and <strong>position y</strong>. Note that these positions work as positive for right/up, and negative for left/down.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Fonts are a little wacky. For a font that works across all styles, use Open Sans. Other fonts will only work in non-Mapbox styles, unless you manually upload those fonts to Mapbox.</span>
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/label-icon-options.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          <strong>Icons</strong> allow you to add a custom image and customize it. By turning Icon on, you can set an <strong>icon image</strong>, set the <strong>scale</strong>, <strong>opacity</strong>, <strong>rotation</strong>, and <strong>anchor</strong>. You can specify an offset from the location point by specifying a <strong>position x</strong> and <strong>position y</strong>. Note that these positions work as positive for right/up, and negative for left/down.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/label-icon-options.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          By using Icons and Labels together, you can (by modifying their positions) create labels with text underneath or above, as you wish.
        </p>
        <h3 id="lines" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Lines</h3>
        <p className="mb-8">
          Lines cover the "LineString" GeoJSON type.
        </p>
        <p className="mb-8">
          Lines have <strong>color</strong>, <strong>opacity</strong>, and <strong>width</strong>. Using Mapbox, lines can also be made into <strong>dashes</strong>, by setting Dash Length and Gap Length.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/line-options.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h3 id="polygons" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Polygons</h3>
        <p className="mb-8">
          Polygons cover the "Polygon" GeoJSON type.
        </p>
        <p className="mb-8">
          Polygons have <strong>color</strong>, <strong>opacity</strong>, and <strong>outline color</strong>.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/polygon-options.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/categories">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Categories</div>
            <div className="text-sm text-gray-600">Learn more about creating categories in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
