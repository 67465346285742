import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Embed & API</h2>
        <p className="mb-8">
          Allowing external users or apps to embed your maps expands the breadth of your creativity hugely. We're also working on API options so you can call your map data from a mobile app! Get in touch if you need this ASAP.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro, the menu items under "Embed & API" will be visible when editing a Map</strong>.
        </p>
        <p className="mb-8">
          To embed a map, simply go to the Embed & API section when on a given Map edit screen, turn on Allow Embed, and copy the embed code that appears underneath. You can give this to other users or place it on other sites as needed.
        </p>
        <h3 id="embed-permissions" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Embed Permissions</h3>
        <p className="mb-8">
          However, you may not want your map to be embeddable anywhere on the internet. To only allow specific users, turn on <strong>Protect Embed</strong> and, in the text area below, enter each domain you want to allow on a separate line.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/embed-api.PNG').default} width="80%" alt="Docs 01" />
        </figure>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/developer">
          <div>
            <div className="text-normal font-medium mb-1">Developer Documentation</div>
            <div className="text-sm text-gray-600">Learn more about meshing your code with Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
