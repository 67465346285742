import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Hover Effects</h2>
        <p className="mb-8">
          Hover Effects add another awesome layer of user interactivity to your site by allowing map features to change on user hover. Note that these things don't change mobile interaction!
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro, Hover Effect sections will become visible inside Locations, Lines, and Polygons</strong>. Currently, Label & Icon do not have hover effects enabled -- please get in touch with us if this is a feature you need!
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/hover-effects.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          By default, Hover Effects use the default settings of any Location, Line, or Polygon. If you only want color to change, for instance, you'll want to make sure that the width and opacity of a line stay the same as your main setting, and only change the color setting.
        </p>
        <p className="mb-8">
          Overall, changing Hover Effects should be quite intuitive.
        </p>

      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/mass-editing">
          <div>
            <div className="text-normal font-medium mb-1">Mass Editing</div>
            <div className="text-sm text-gray-600">Mass Editing can be a great alternative to complicated imports or spending hours changing styles.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/advanced-import">
          <div>
            <div className="text-normal font-medium mb-1">Importing in Mapster WP Maps Pro</div>
            <div className="text-sm text-gray-600">You may want to use the advanced importer instead of mass editing.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
