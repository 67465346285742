import React from 'react';

function AboutIntro() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Going Pro </span>
               with Mapster
             </h1>
            <p className="text-xl text-gray-600">
              Are you a master mapmaker, in need of some super sweet features? Take a look below and see if <strong>Mapster Maps Pro</strong> is right for you.
            </p>
            <div style={{marginTop: 20 }}>
              <a href="https://checkout.freemius.com/mode/dialog/plugin/10260/plan/17314/?show_monthly_switch=true" style={{marginRight: 10}} className="btn bg-blue-600 hover:bg-blue-700 w-full mb-4 text-white sm:w-auto sm:mb-0">Download</a>
              <a href="/documentation/pro-introduction" className="btn text-gray-600 hover:text-gray-900 w-full mb-4 sm:w-auto sm:mb-0">See Pro Docs</a>
            </div>
          </div>

          <figure className="flex justify-center items-start">
            <img className="rounded shadow-2xl" src={require('../images/3d-map.jpg').default} width="768" height="432" alt="About us" />
          </figure>

        </div>
      </div>
    </section>
  );
}

export default AboutIntro;
