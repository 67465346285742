import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Header from '../partials/Header';
import DocumentationNavigation from '../partials/DocumentationNavigation';
import DocumentationContentIntroduction from '../partials/DocumentationContentIntroduction';
import DocumentationContentGeographyTypes from '../partials/DocumentationContentGeographyTypes';
import DocumentationContentMaps from '../partials/DocumentationContentMaps';
import DocumentationContentGoogleMaps from '../partials/DocumentationContentGoogleMaps';
import DocumentationContentPopups from '../partials/DocumentationContentPopups';
import DocumentationContentCategories from '../partials/DocumentationContentCategories';
import DocumentationContentSettings from '../partials/DocumentationContentSettings';
import DocumentationContentDeveloper from '../partials/DocumentationContentDeveloper';
import DocumentationMasterList from '../partials/DocumentationMasterList';

import ProContentIntroduction from '../partials/ProContentIntroduction';
import ProContentStoreLocator from '../partials/ProContentStoreLocator';
import ProContentUserSubmission from '../partials/ProContentUserSubmission';
import ProContentCategoryFilter from '../partials/ProContentCategoryFilter';
import ProContentMassEdit from '../partials/ProContentMassEdit';
import ProContentEmbedAPI from '../partials/ProContentEmbedAPI';
import ProContentHoverEffects from '../partials/ProContentHoverEffects';
import ProContentImport from '../partials/ProContentImport';
import ProContentPopups from '../partials/ProContentPopups';
import ProContentMiscellaneous from '../partials/ProContentMiscellaneous';
import Footer from '../partials/Footer';

function Documentation() {

  const location = useLocation();

  window.location.href = "https://wpmaps-docs.mapster.me/"

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                {/* Sidebar */}
                <DocumentationNavigation />

                {/* Page container */}
                {location.pathname === '/documentation' ? <DocumentationContentIntroduction /> : false}
                {location.pathname.indexOf('/documentation/intro') > -1 ? <DocumentationContentIntroduction /> : false}
                {location.pathname.indexOf('/documentation/geography-types') > -1 ? <DocumentationContentGeographyTypes /> : false}
                {location.pathname.indexOf('/documentation/popups') > -1 ? <DocumentationContentPopups /> : false}
                {location.pathname.indexOf('/documentation/maps') > -1 ? <DocumentationContentMaps /> : false}
                {location.pathname.indexOf('/documentation/google-maps') > -1 ? <DocumentationContentGoogleMaps /> : false}
                {location.pathname.indexOf('/documentation/categories') > -1 ? <DocumentationContentCategories /> : false}
                {location.pathname.indexOf('/documentation/settings') > -1 ? <DocumentationContentSettings /> : false}
                {location.pathname.indexOf('/documentation/developer') > -1 ? <DocumentationContentDeveloper /> : false}

                {location.pathname.indexOf('/documentation/pro-introduction') > -1 ? <ProContentIntroduction /> : false}
                {location.pathname.indexOf('/documentation/store-locator') > -1 ? <ProContentStoreLocator /> : false}
                {location.pathname.indexOf('/documentation/user-submission') > -1 ? <ProContentUserSubmission /> : false}
                {location.pathname.indexOf('/documentation/advanced-category-filter') > -1 ? <ProContentCategoryFilter /> : false}
                {location.pathname.indexOf('/documentation/mass-editing') > -1 ? <ProContentMassEdit /> : false}
                {location.pathname.indexOf('/documentation/embed-api') > -1 ? <ProContentEmbedAPI /> : false}
                {location.pathname.indexOf('/documentation/hover-effects') > -1 ? <ProContentHoverEffects /> : false}
                {location.pathname.indexOf('/documentation/advanced-import') > -1 ? <ProContentImport /> : false}
                {location.pathname.indexOf('/documentation/advanced-popups') > -1 ? <ProContentPopups /> : false}
                {location.pathname.indexOf('/documentation/miscellaneous') > -1 ? <ProContentMiscellaneous /> : false}

                {location.pathname === '/documentation/master-list' ? <DocumentationMasterList /> : false}

              </div>

            </div>
          </div>
        </section>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Documentation;
