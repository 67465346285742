import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Advanced Import</h2>
        <p className="mb-8">
          While the non-pro version of Mapster WP Maps has a pretty robust Import system, Pro features make it much more flexible and ensure it works with larger datasets.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro</strong>, more import options will immediately appear in Settings.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/CMv0m2doVfU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <p className="mb-8">
          Go to Maps {">"} Settings and click on the Data Importer button. In addition to the default functionalities (validating your file, basic reprojection, assigning a category), much more is now included.
        </p>
        <p className="mb-8">
          All the properties on your data file will be retained as well, in each feature under "Custom Properties" after import. These can later be used in advanced <a className="text-blue-500" href="/pro/advanced-category-filter">Category Filters</a> or <a className="text-blue-500" href="/pro/advanced-popups">Popups</a>.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/custom-properties.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <h3 id="large-imports" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Large Imports</h3>
        <p className="mb-8">
          A shapefile or geoJSON with any number of features will now import. This is done in batches of 50 at a time, so it may take some time to fully execute, but let the progress bar fill after you press Import.
        </p>
        <h3 id="category-import" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Category Import</h3>
        <p className="mb-8">
          After you upload a valid geographic file, you will be able to assign a category to those features so they will be more easily able to be added to a map after import.
        </p>
        <h3 id="conditional-styling" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Conditional Styling</h3>
        <p className="mb-8">
          We've introduced a fairly deep system for importing with custom styles, based on the different properties or data uploaded in your file.
        </p>
        <p className="mb-8">
          After you upload a valid geographic file, options will appear on the right under "Conditional Styling and Popups".
        </p>
        <p className="mb-8">
          You can create different conditions for each geographic type (Points, Lines, and Polygons). Each condition contains Rules, a Template, Properties, and Popups styling information.
        </p>
        <p className="mb-8">
          <strong>Rules</strong> tell the importer which features this condition applies to. By default, rules are applied to all features. Press the Rules button to open the rules section and choose which features this condition will apply to.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/import-rules-condition.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          <strong>Template</strong> allows you to assign a specific "template" or "base" style, based on an existing feature you've already created, to this condition. Just enter the ID of the feature (you can find this by going to Locations, Lines, or Polygons, and looking for the ID in the list of posts on the right). All the features matching this condition will inherit the template's style.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/import-id-list.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          <strong>Properties</strong> allow you to modify very specific inputs. You can set everything that you would normally set in the interface, from the Post Title to the color, border width, and so on. You can select to use a value from a property on the data, or enter in a direct input if you choose instead. Direct inputs will override property values.
        </p>
        <p className="mb-8">
          If you are confused about which properties mean what, they correspond exactly to the properties you can set manually when creating a feature in the Wordpress backend. For instance, <em>label_text_properties_text-field</em> is the "Text Field" property when creating a label Location. Default values are also shown to give you a hint on what the values should be.
        </p>
        <p className="mb-8">
          <strong>Popups</strong> allow you to modify specific inputs for popups across this condition. You can turn on popups using direct input "1" on <em>enable_popup</em>, and then set text or other properties based on feature properties.
        </p>
        <h3 id="tips" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Tips</h3>
        <p className="mb-8">
          The importer can certainly be a little confusing! It's meant for advanced users. If you are having trouble, we'd recommend importing using categories for different subsections of your data, and then styling using the <a className="text-blue-500" href="/pro/documentation/mass-editing">Mass Edit</a> tool, which is a bit more intuitive.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/settings">
          <div>
            <div className="text-normal font-medium mb-1">Importing in Mapster WP Maps</div>
            <div className="text-sm text-gray-600">Learn about the basic importer tool.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/mass-editing">
          <div>
            <div className="text-normal font-medium mb-1">Mass Editing</div>
            <div className="text-sm text-gray-600">Mass Editing can be a great alternative to complicated imports or spending hours changing styles.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
