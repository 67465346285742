import React from 'react';

function AboutStory() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6" style={{marginTop: 40}}>
        <div className="pb-12 md:pb-20">

          <div className="sm:flex" style={{marginBottom: 40}}>
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/native-land-example.PNG').default} width="600" height="800" alt="About us 02" />
            </figure>
            <div className="sm:ml-8 lg:ml-16 vertical-align-text">
              <div>
                <h4 className="h4 mb-2">Data Management</h4>
                <p className="text-lg text-gray-600 mb-8">
                  Our plugin is in use on the site <a className="text-blue-600" href="https://native-land.ca/">Native-Land.ca</a>, where it helps the team to manage thousands of distinct polygons, connect and keep synced to Mapbox, and serve a public API.
                </p>
              </div>
            </div>
          </div>

          <div className="sm:flex" style={{marginBottom: 40}}>
            <div className="sm:mr-8 lg:mr-16 vertical-align-text">
              <div>
                <h4 className="h4 mb-2">Filter & Search</h4>
                <p className="text-lg text-gray-600 mb-8">
                  Create more advanced filters, including custom fields created in league with ACF (Advanced Custom Fields) and more detailed styling including hover changes.
                </p>
              </div>
            </div>
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/filtering-example.PNG').default} width="600" height="800" alt="About us 02" />
            </figure>
          </div>

          <div className="sm:flex">
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/store-locator-example.PNG').default} width="600" height="800" alt="About us 02" />
            </figure>
            <div className="sm:ml-8 lg:ml-16 vertical-align-text">
              <div>
                <h4 className="h4 mb-2">Store Locator</h4>
                <p className="text-lg text-gray-600 mb-8">
                  Use our base Store Locator layout and functionalities to create pretty, distinct store locators on your sites. Set opening and closing hours, custom icons, and more.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default AboutStory;
