import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { basic, pro } from '../utils/doc-items';

function DocumentationNavigation() {

  const location = useLocation();

  const [sidebarNavOpen, setSidebarNavOpen] = useState(false);
  const [sidebarLinkOpen, setSidebarLinkOpen] = useState(true);

  return (
    <aside className="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
      <div>

        {/* Search form */}
        {/* <form className="mb-4 pb-4 border-b border-gray-200">
          <div className="flex flex-wrap">
            <div className="w-full">
              <label className="block text-sm sr-only" htmlFor="search">Search</label>
              <div className="relative flex items-center">
                <input id="search" type="search" className="form-input w-full text-gray-800 px-3 py-2 pl-10" placeholder="Search the docs" />
                <button type="submit" className="absolute inset-0 right-auto" aria-label="Search">
                  <svg className="w-4 h-4 fill-current text-gray-400 mx-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </form> */}

        <button className="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden" onClick={(e) => { e.preventDefault(); setSidebarNavOpen(!sidebarNavOpen); }}>
          <span>Docs navigation</span>
          <svg className="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${sidebarNavOpen && 'rotate-180'}`} />
            <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${sidebarNavOpen && 'rotate-180'}`} />
          </svg>
        </button>


        {/* Docs nav */}
        <nav className={`md:block ${!sidebarNavOpen && 'hidden'}`}>
          <ul className="font-medium -my-2">
            {/* 1st level */}
            {basic.map(parent => {
              return (
                <li className="py-2">
                  <Link to={parent.slug} className="flex items-center hover:underline">
                    <div className="flex items-center flex-grow">
                      <div dangerouslySetInnerHTML={{ __html : parent.icon }} />
                      <span>{parent.name}</span>
                    </div>
                  </Link>
                  {/* 2nd level */}
                  {parent.inner ?
                    <ul
                      className={`font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300 ${location.pathname.indexOf(parent.slug) > -1 ? '' : 'hidden'}`}
                    >
                      {parent.inner.map(child => {
                        return (
                          <li className="py-1">
                            <a className="text-gray-600 hover:underline" href={child.slug}>{child.name}</a>
                          </li>
                        )
                      })}
                    </ul>
                  : false}
                </li>
              )
            })}

            <hr style={{marginTop: '20px', marginBottom: '20px'}} />
            <h3 style={{fontSize: 30, fontWeight: 'bold'}}>Pro</h3>

            {pro.map(parent => {
              return (
                <li className="py-2">
                  <Link to={parent.slug} className="flex items-center hover:underline">
                    <div className="flex items-center flex-grow">
                      <div dangerouslySetInnerHTML={{ __html : parent.icon }} />
                      <span>{parent.name}</span>
                    </div>
                  </Link>
                  {/* 2nd level */}
                  {parent.inner ?
                    <ul
                      className={`font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300 ${location.pathname.indexOf(parent.slug) > -1 ? '' : 'hidden'}`}
                    >
                      {parent.inner.map(child => {
                        return (
                          <li className="py-1">
                            <a className="text-gray-600 hover:underline" href={child.slug}>{child.name}</a>
                          </li>
                        )
                      })}
                    </ul>
                  : false}
                </li>
              )
            })}

            <hr style={{marginTop: '20px', marginBottom: '20px'}} />

            <li className="py-1">
              <a className="text-gray-600 hover:underline" href="/documentation/master-list">Docs Master List</a>
            </li>
          </ul>
        </nav>

      </div>
    </aside>
  );
}

export default DocumentationNavigation;
