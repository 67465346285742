import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Header from '../partials/Header';
import ProHeroHome from '../partials/ProHeroHome';
import ProFeaturesHome from '../partials/ProFeaturesHome';
import ProFeaturesBlocks from '../partials/ProFeaturesBlocks';
import PricingTables from '../partials/PricingTables';
import FeaturesTable from '../partials/FeaturesTable';
import TestimonialsCarousel from '../partials/TestimonialsCarousel';
import AboutStory from '../partials/AboutStory';
import Stats from '../partials/Stats';
import Faqs from '../partials/Faqs';
import Footer from '../partials/Footer';

function Addons() {

  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <ProHeroHome />
        <ProFeaturesHome />
        <AboutStory />
        <ProFeaturesBlocks />
        <TestimonialsCarousel />
        <Faqs />


      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Addons;
