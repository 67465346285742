import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Google Maps</h2>
        <p className="mb-8">
          Google Maps is integrated! Enjoy.
        </p>
        <p className="mb-8">
          You can find plenty of documentation across the site for mapping, which was written originally for the Mapbox and Maplibre maps. While our Google Maps integration supports almost all the same features, there are a few small changes worth noting that we'll go over here.
        </p>
        <h3 id="map-style" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Map Style</h3>
        <p className="mb-8">
          First, you can set a Snazzy Maps style from the JSON on the <a className="text-blue-500" href="https://snazzymaps.com/">Snazzy maps site</a>. Copy this JSON and paste it into the textarea for Snazzy Maps JSON on the Map tab in your Mapster WP Map.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/snazzy-maps.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h3 id="controls" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Controls</h3>
        <p className="mb-8">
          There are a few different controls for Google Maps. The <strong>Directions</strong> control and <strong>3D</strong> control are not present.
        </p>
        <p className="mb-8">
          However, there is a <strong>Map Type</strong> control, with which you can switch the underlying map style, as well as a <strong>Street View</strong> control.
        </p>
        <h3 id="notes" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Notes</h3>
        <p className="mb-8">
          A few small differences also exist.
          <ul style={{marginTop: 10, listStyleType : 'disc', marginLeft: 40}}>
            <li>No ability to customize a popup tip</li>
            <li>No rotation on marker, icon, and text</li>
            <li>No position X or Y on labels</li>
            <li>No circle or line/polygon clustering</li>
          </ul>
        </p>
        <p className="mb-8">
          If these features missing causes you trouble, please get in touch with us.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/maps">
          <div>
            <div className="text-normal font-medium mb-1">Maps Documentation</div>
            <div className="text-sm text-gray-600">Learn more about all the general map options and customization available.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
