import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Settings</h2>
        <p className="mb-8">
          Mapster WP Maps is built to allow developers to easily extend and tweak map display and settings. We have a huge number of ways you can hook into specific maps during runtime to execute functions and modify content.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/add-api-key.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h3 id="custom-scripts" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Custom Scripts</h3>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ZAuJYJ6IoA8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <p className="mb-8">
          Custom Scripts are scripts you can write somewhere in the Javascript on your site, and then give to a specific map to call after it's done initializing. This is a simple way to hook into any given map and manipulate displays or controls as you need.
        </p>
        <p className="mb-8">
          For any particular map, go to the Custom section. Enter the name of a function here. For example, let's call our function "myCustomScript".
        </p>
        <p className="mb-8">
          Create a global function with that script name. Your script will automatically recieve a "map" and a "features" variable. As follows:
          <br /><br />
          <pre className="text-sm sm:text-base mb-4 text-black-600 ">
            window.myCustomScript = function(map, features) {"{"}{"\n\r"}
              {"\t"}console.log(map);{"\n\r"}
              {"\t"}console.log(features);{"\n\r"}
            {"}"}
          </pre>
          From here, make the modifications you need!
        </p>
        <h3 id="hooks" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Hooks</h3>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/wwxYvxsknmM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <p className="mb-8">
          A "hook" in our parlance is, like in Wordpress generally, a place where you can "hang" a function that you want to run. Every hook recieves certain variables and can be registered to run on a specific map.
        </p>
        <p className="mb-8">
          For example, let's say we need to resize the map in some special way after the page loads.
        </p>
        <p className="mb-4">
          We'll register our hook before the map runs:
        </p>
        <pre className="text-sm sm:text-base mb-4 text-black-600 " style={{background: '#eee', padding: '0px 20px'}} dangerouslySetInnerHTML={{__html : `
mwm.add_action('map_set', (map) => {
  // Do something with the map
  map.on('load', () => {
    map.setZoom(12);
  })
})
        `}} />
        <p className="mb-8">
          If you want to only target a specific map on a specific page, you can also pass the map ID (found by hovering over the map in the Maps {">"} Maps screen, or in the URL when editing the map) to the hook function as follows:
        </p>
        <pre className="text-sm sm:text-base mb-4 text-black-600 " style={{background: '#eee', padding: '0px 20px'}} dangerouslySetInnerHTML={{__html : `
mwm.add_action('map_set/mapster-7314', (map) => {
  // Do something with the map
  map.on('load', () => {
    map.setZoom(12);
  })
})
        `}} />
        <p className="mb-8">
          See below for lists of hooks as well as what data they provide. Get in touch if what you need isn't here!
        </p>
        <h4 className="mb-8">
          <strong>General Hooks</strong>
        </h4>
        <table class="table-auto w-full border-b border-gray-200 mb-8">
          <thead className="text-base sm:text-lg border-t border-gray-200">
            <tr>
              <th className="text-bold text-left px-2 py-4">Hook</th>
              <th className="text-bold text-left px-2 py-4">Args</th>
              <th className="text-bold text-left px-2 py-4">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">access_token_set</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After Access Token is set, if present.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">data_fetched</td>
              <td className="text-sm sm:text-base pr-2 py-4">postResponse</td>
              <td className="text-xs xs:text-base pr-2 py-4">After all data to load map is fetched.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">map_size_set</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After map DOM element size is initially set.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">map_library_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">mapLibrary</td>
              <td className="text-xs xs:text-base pr-2 py-4">After map library (MapLibre or Mapbox) is set.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">map_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">map</td>
              <td className="text-xs xs:text-base pr-2 py-4">After map variable is initialized.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">set_interactivity</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After map interactivity is enabled or disabled.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">set_terrain</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After map 3D terrain is enabled.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">loading_icon_started</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After loading icon appears.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">loading_icon_done</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After loading is completed.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">map_resize_set</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">Whenever map is resized during runtime.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">map_features_set</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After map features set as Mapbox layers are placed onto map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">set_clustering</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After map layers clustering has completed.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">set_customscripts</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After custom scripts are run.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">set_mapstyle</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After anytime the map style changes.</td>
            </tr>
          </tbody>
        </table>
        <h4 className="mb-8">
          <strong>Controls Hooks</strong>
        </h4>
        <table class="mb-8 table-auto w-full border-b border-gray-200">
          <thead className="text-base sm:text-lg border-t border-gray-200">
            <tr>
              <th className="text-bold text-left px-2 py-4">Hook</th>
              <th className="text-bold text-left px-2 py-4">Args</th>
              <th className="text-bold text-left px-2 py-4">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">zoom_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">zoomControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">geocoder_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">geocoderControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">directions_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">directionsControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">3d_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">3dControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">scale_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">scaleControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">geolocation_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">geolocationControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">fullscreen_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">fullscreenControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">customSearch_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">customSearchControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">filterDropdown_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">filterDropdownControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">categoryFilter_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">categoryFilterControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">list_control_set</td>
              <td className="text-sm sm:text-base pr-2 py-4">listControl</td>
              <td className="text-xs xs:text-base pr-2 py-4">After this control is set onto the map.</td>
            </tr>
          </tbody>
        </table>
        <h4 className="mb-8">
          <strong>Interaction Hooks</strong>
        </h4>
        <table class="mb-8 table-auto w-full border-b border-gray-200">
          <thead className="text-base sm:text-lg border-t border-gray-200">
            <tr>
              <th className="text-bold text-left px-2 py-4">Hook</th>
              <th className="text-bold text-left px-2 py-4">Args</th>
              <th className="text-bold text-left px-2 py-4">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">layer_feature_clicked</td>
              <td className="text-sm sm:text-base pr-2 py-4">clickedFeature</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a click event occurs on a layer feature.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">layer_feature_hovered</td>
              <td className="text-sm sm:text-base pr-2 py-4">hoveredFeature</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a hover event occurs on a layer feature.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">popup_opened_from_layer_click</td>
              <td className="text-sm sm:text-base pr-2 py-4">clickedPopup</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a popup is opened from a layer feature click.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">popup_opened_from_layer_hover</td>
              <td className="text-sm sm:text-base pr-2 py-4">hoveredPopup</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a popup is opened from a layer feature hover.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">popup_closed_from_layer_hover</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After a popup is closed from a layer feature mouseout.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">external_link_opened</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After a user is redirected to an external link.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">marker_feature_clicked</td>
              <td className="text-sm sm:text-base pr-2 py-4">clickedFeature</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a click event occurs on a marker feature.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">marker_feature_hovered</td>
              <td className="text-sm sm:text-base pr-2 py-4">hoveredFeature</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a hover event occurs on a marker feature.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">popup_opened_from_marker_click</td>
              <td className="text-sm sm:text-base pr-2 py-4">clickedPopup</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a popup is opened from a marker feature click.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">popup_opened_from_marker_hover</td>
              <td className="text-sm sm:text-base pr-2 py-4">hoveredPopup</td>
              <td className="text-xs xs:text-base pr-2 py-4">After a popup is opened from a marker feature hover.</td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="text-sm sm:text-base pr-2 py-4 text-green-600">popup_closed_from_marker_hover</td>
              <td className="text-sm sm:text-base pr-2 py-4"></td>
              <td className="text-xs xs:text-base pr-2 py-4">After a popup is closed from a marker feature mouseout.</td>
            </tr>
          </tbody>
        </table>
        <h3 id="internationalization" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Internationalization (WPML)</h3>
        <p className="mb-8">
          Need to include translations to other languages, or translate the entire plugin for a client? We've set everything up to work with WPML.
        </p>
        <p className="mb-8">
          You'll need to first enable the Mapster WP maps Post Types in WPML. Go to WPML {">"} Settings {">"} Post Types Translation, and change Maps, Locations, Lines, and Polygons to "Translatable".
        </p>
        <p className="mb-8">
          Then enable Map Category translation: go to WPML {">"} Settings {">"} Taxonomies Translation, and change Map Categories to "Translatable".
        </p>
        <p className="mb-8">
          Now you'll be able to make copies of your maps in different languages, and these will automatically pull any category or other translations from your settings.
        </p>
        <p className="mb-8">
          If you need to translate all the backend language, as well, that can be done through WPML's String Translation plugin. Enable this plugin, go to WPML {">"} Theme and plugins localization {">"} Strings in the plugins and select Mapster WP Maps. Scan the plugin for strings.
        </p>
        <p className="mb-8">
          Head over to WPML {">"} String Translation and enter translations where you think they are needed. Presto -- internationalized!
        </p>
        <p className="mb-8">
          Note that by default, translations will only appear in the front end of the site. If you want backend translation as well, you will need to enable "Set admin language as editing language" on the Profile page in the Users area of WP.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Pro</div>
            <div className="text-sm text-gray-600">Documentation for the Pro version of the plugin.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
