export const basic = [{
  slug : '/documentation/introduction',
  name : 'Introduction',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3.294L1.4 1.035C1.1.847.7.941.4 1.13c-.2.189-.4.471-.4.753v10.353c0 .377.2.753.6.847L7 15.718V3.294zM15.6 1.13c-.3-.189-.6-.189-.9-.095L9 3.295v12.423l6.4-2.542c.4-.188.6-.47.6-.847V1.882c0-.282-.2-.564-.4-.753z" />
    </svg>
  `,
  inner : [{
    slug : '#installation',
    name : 'Installation'
  },{
    slug : '#overview',
    name : 'Overview'
  },{
    slug : '#tokens',
    name : 'Tokens and API Keys'
  }]
},{
  slug : '/documentation/geography-types',
  name : 'Geography Types',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 15.414l-2.5-3V1a1 1 0 011-1h3a1 1 0 011 1v11.414l-2.5 3zM7 0H1C.4 0 0 .4 0 1v2h3v2H0v2h3v2H0v2h3v2H0v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z" />
    </svg>
  `,
  inner : [{
    slug : '#add-edit',
    name : 'Add & Edit'
  },{
    slug : '#locations',
    name : 'Locations'
  },{
    slug : '#lines',
    name : 'Lines'
  },{
    slug : '#polygons',
    name : 'Polygons'
  }]
},{
  slug : '/documentation/popups',
  name : 'Popups',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.5" cy="4.5" r="4.5" />
      <circle cx="12" cy="13" r="3" />
      <circle cx="14.5" cy="5.5" r="1.5" />
    </svg>
  `,
  inner : [{
    slug : '#styles',
    name : 'Templates'
  },{
    slug : '#contents',
    name : 'Contents'
  }]
},{
  slug : '/documentation/maps',
  name : 'Maps',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9H1a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v7a1 1 0 01-1 1zM6 16H1a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1zM15 6h-5a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v4a1 1 0 01-1 1zM15 16h-5a1 1 0 01-1-1V9a1 1 0 011-1h5a1 1 0 011 1v6a1 1 0 01-1 1z" />
    </svg>
  `,
  inner : [{
    slug : '#setup',
    name : 'Map Setup'
  },{
    slug : '#features',
    name : 'Manage Features'
  },{
    slug : '#interactivity',
    name : 'Interactivity'
  },{
    slug : '#controls',
    name : 'Controls'
  },{
    slug : '#filters-lists',
    name : 'Filters & Lists'
  },{
    slug : '#custom-scripts',
    name : 'Custom Scripts'
  }]
},{
  slug : '/documentation/google-maps',
  name : 'Google Maps',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3">
      <path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"/>
      <path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"/>
      <path fill="#4285f4" d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"/>
      <path fill="#fbbc04" d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"/><path fill="#34a853" d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"/>
    </svg>
  `,
  inner : [{
    slug : '#map-style',
    name : 'Map Style'
  },{
    slug : '#controls',
    name : 'Controls'
  },{
    slug : '#notes',
    name : 'Notes'
  }]
},{
  slug : '/documentation/categories',
  name : 'Categories',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
    </svg>
  `,
},{
  slug : '/documentation/settings',
  name : 'Settings',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle class="fill-current text-blue-800" cx="8" cy="8" r="8" />
    </svg>
  `,
  inner : [{
    slug : '#import-geo-file',
    name : 'Import Geographic File'
  },{
    slug : '#import-gl-js',
    name : 'Import GL JS'
  }]
},{
  slug : '/documentation/developer',
  name : 'For Developers',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 mr-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
    </svg>
  `,
  inner : [{
    slug : '#custom-scripts',
    name : 'Custom Scripts'
  },{
    slug : '#hooks',
    name : 'Hooks'
  },{
    slug : '#internationalization',
    name : 'Internationalization (WPML)'
  }]
}]

export const pro = [{
  slug : '/documentation/pro-introduction',
  name : 'Introduction',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3.294L1.4 1.035C1.1.847.7.941.4 1.13c-.2.189-.4.471-.4.753v10.353c0 .377.2.753.6.847L7 15.718V3.294zM15.6 1.13c-.3-.189-.6-.189-.9-.095L9 3.295v12.423l6.4-2.542c.4-.188.6-.47.6-.847V1.882c0-.282-.2-.564-.4-.753z" />
    </svg>
  `,
  inner : [{
    slug : '#installation',
    name : 'Installation'
  }]
},{
  slug : '/documentation/hover-effects',
  name : 'Hover Effects',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
    </svg>
  `,
  inner : []
},{
  slug : '/documentation/store-locator',
  name : 'Store Locator',
  icon : `
    <svg class="w-3 h-3 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    </svg>
  `,
  inner : [{
    slug : '#adding-locations',
    name : 'Adding Locations'
  },{
    slug : '#making-locator-map',
    name : 'Making Map'
  },{
    slug : '#tips',
    name : 'Tips'
  }]
},{
  slug : '/documentation/user-submission',
  name : 'User Submission',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 15.414l-2.5-3V1a1 1 0 011-1h3a1 1 0 011 1v11.414l-2.5 3zM7 0H1C.4 0 0 .4 0 1v2h3v2H0v2h3v2H0v2h3v2H0v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z" />
    </svg>
  `,
  inner : [{
    slug : '#global-options',
    name : 'Global Options'
  },{
    slug : '#page-templates',
    name : 'Page Templates'
  },{
    slug : '#custom-fields',
    name : 'Custom Fields'
  },{
    slug : '#front-end-form',
    name : 'Front-end Form'
  },{
    slug : '#search-form',
    name : 'Search Form'
  },{
    slug : '#tips',
    name : 'Tips'
  }]
},{
  slug : '/documentation/advanced-popups',
  name : 'Advanced Popups',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.5" cy="4.5" r="4.5" />
      <circle cx="12" cy="13" r="3" />
      <circle cx="14.5" cy="5.5" r="1.5" />
    </svg>
  `,
  inner : [{
    slug : '#popup-modal',
    name : 'Popup to Modal'
  },{
    slug : '#advanced-syntax',
    name : 'Advanced Syntax'
  },{
    slug : '#custom-html',
    name : 'Custom HTML'
  }]
},{
  slug : '/documentation/mass-editing',
  name : 'Mass Editing',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9H1a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v7a1 1 0 01-1 1zM6 16H1a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1zM15 6h-5a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v4a1 1 0 01-1 1zM15 16h-5a1 1 0 01-1-1V9a1 1 0 011-1h5a1 1 0 011 1v6a1 1 0 01-1 1z" />
    </svg>
  `,
  inner : [{
    slug : '#edit-screen',
    name : 'Edit Screen'
  }]
},{
  slug : '/documentation/embed-api',
  name : 'Embed & API',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
    </svg>
  `,
  inner : [{
    slug : '#embed-permissions',
    name : 'Embed Permissions'
  }]
},{
  slug : '/documentation/advanced-category-filter',
  name : 'Advanced Category Filter',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
    </svg>
  `,
  inner : [{
    slug : '#filter-options',
    name : 'Filter Options'
  },{
    slug : '#custom-fields',
    name : 'Integration with Custom Fields'
  }]
},{
  slug : '/documentation/advanced-import',
  name : 'Advanced Import',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle class="fill-current text-blue-800" cx="8" cy="8" r="8" />
    </svg>
  `,
  inner : [{
    slug : '#large-imports',
    name : 'Large Imports'
  },{
    slug : '#category-import',
    name : 'Category Import'
  },{
    slug : '#conditional-styling',
    name : 'Conditional Styling'
  },{
    slug : '#tips',
    name : 'Tips'
  }]
},{
  slug : '/documentation/miscellaneous',
  name : 'Miscellaneous',
  icon : `
    <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle class="fill-current text-blue-800" cx="8" cy="8" r="8" />
    </svg>
  `,
  inner : [{
    slug : '#controls',
    name : 'Controls'
  }]
}]
