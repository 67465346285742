import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Popups</h2>
        <p className="mb-8">
          Popups in Mapster WP Maps are always attached to a geography feature, and created in that feature's custom post type. You can add popups to any type of feature -- Locations, Lines, or Polygons.
        </p>
        <p className="mb-8">
          You can also create custom Popup Templates with some easy-to-set options, or implement your own custom CSS if you prefer.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Be aware that some styles from your theme can sometimes get into your popups -- if you're having trouble, inspect the CSS and see if you can override it. If you need help, get in touch.</span>
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ZBvQtot_8vQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="template" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Templates</h3>
        <p className="mb-8">
          Popup Templates are design schemas for your popups. This way, you can pick what elements you want to have in different popups you'll use. You don't set the actual content of popups here -- this is just a template creation, and you'll fill in the real content on each geography feature you create.
        </p>
        <p className="mb-8">
          If you click "Popup Templates" on the left-hand side of the menu, you'll enter into another custom post type. This comes preset with a few popup styles that we like, but you can feel free to create your own too.
        </p>
        <p className="mb-8">
          Popup Templates provide you with a basic preview of a single popup, and some basic pre-set placeholder content.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/popup-templates.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h4 className="h4 text-gray-900 mb-8">Layout</h4>
        <p className="mb-8">
          You can <strong>enable/disable</strong> each part of the popup, including the Header, Image, Body, Footer, and Pointer. You can also set a custom image height, but the width is always set to 100%.
        </p>
        <h4 className="h4 text-gray-900 mb-8">Background</h4>
        <p className="mb-8">
          You can <strong>set</strong> background colors for the general background, header, body, footer, button, and pointer of the popup. These colors also accept transparency values.
        </p>
        <h4 className="h4 text-gray-900 mb-8">Text</h4>
        <p className="mb-8">
          Choose your <strong>text alignment</strong>, and text colors for the Header, Body, and Button.
        </p>
        <h4 className="h4 text-gray-900 mb-8">CSS</h4>
        <p className="mb-8">
          You can enter a custom class to the popups which you can then style from your own CSS file -- or just enter in custom CSS directly. The custom CSS field is pre-filled with the default CSS already in use on the popup, so you can easily make changes.
        </p>
        <h4 className="h4 text-gray-900 mb-8">Options</h4>
        <p className="mb-8">
          Finally, you have a few other options, such as changing the <strong>max width</strong>, adding or removing a default <strong>close button</strong>, and choosing whether or not to have the popup <strong>close on click</strong> or <strong>close on move</strong> when the user clicks or moves elsewhere on the map.
        </p>
        <h3 id="contents" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Contents</h3>
        <p className="mb-8">
          You set the actual contents of popups inside each geography feature post, by scrolling down under the settings for the geography feature's styling.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/popup-contents.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          First, enable a popup. Then you can choose your <strong>popup template</strong>, and the <strong>interaction</strong> behaviour (click or mouseover).
        </p>
        <p className="mb-8">
          Then, set whatever parts of the template you have enabled, which can include <strong>header text</strong>, an <strong>image</strong>, <strong>body text (HTML)</strong>, and <strong>button text</strong>. You can also set a <strong>button action</strong>, going directly to that post's link, or setting a custom URL if you prefer. You can also choose whether to open this link in the same page or a new tab.
        </p>
        <p className="mb-8">
          Note that you can also choose between having a single featured image or having a gallery of images -- whatever you need!
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Do you have ideas for new popup features? Get in touch with us!</span>
        </p>
        <p className="mb-8">
          Optionally, you can also set a <strong>link</strong> for your geography to open as soon as it is clicked. Just press the Open Link on Click option, and set your URL there.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/popups">
          <div>
            <div className="text-normal font-medium mb-1">Mapster Pro Popup Documentation</div>
            <div className="text-sm text-gray-600">Learn more about editing popups and popup templates in the pro version of the plugin.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
