import React from 'react';
import { Link } from 'react-router-dom';
import { basic, pro } from '../utils/doc-items';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Doc Master List</h2>
        <p className="mb-8">
          In case you're having trouble finding anything, here's a full visible list of all the documentation we have.
        </p>
        <ul className="font-medium -my-2">
          {/* 1st level */}
          {basic.map(parent => {
            return (
              <li className="py-2">
                <Link to={parent.slug} className="flex items-center hover:underline">
                  <div className="flex items-center flex-grow">
                    <div dangerouslySetInnerHTML={{ __html : parent.icon }} />
                    <span>{parent.name}</span>
                  </div>
                </Link>
                {/* 2nd level */}
                {parent.inner ?
                  <ul
                    className={`font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300`}
                  >
                    {parent.inner.map(child => {
                      return (
                        <li className="py-1">
                          <a className="text-gray-600 hover:underline" href={parent.slug + '/' + child.slug}>{child.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                : false}
              </li>
            )
          })}

          <hr style={{marginTop: '20px', marginBottom: '20px'}} />
          <h3 style={{fontSize: 30, fontWeight: 'bold'}}>Pro</h3>

          {pro.map(parent => {
            return (
              <li className="py-2">
                <Link to={parent.slug} className="flex items-center hover:underline">
                  <div className="flex items-center flex-grow">
                    <div dangerouslySetInnerHTML={{ __html : parent.icon }} />
                    <span>{parent.name}</span>
                  </div>
                </Link>
                {/* 2nd level */}
                {parent.inner ?
                  <ul
                    className={`font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300`}
                  >
                    {parent.inner.map(child => {
                      return (
                        <li className="py-1">
                          <a className="text-gray-600 hover:underline" href={parent.slug + '/' + child.slug}>{child.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                : false}
              </li>
            )
          })}
        </ul>

      </div>

    </div>
  );
}

export default DocumentationContent;
