import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Categories</h2>
        <p className="mb-8">
          Categories allow you to easily group together different features. Any feature can have any number of categories attached to it.
        </p>
        <h3 id="category-import" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Importing and User Submissions</h3>
        <p className="mb-8">
          Categories can be used to "mass add" features to a map, or have features for a given map update automatically when a new feature is added to that category. This is very helpful when it comes to importing features in the Settings page, or allowing user submissions to automatically be added to an existing map. To do this, just add the category to your map (under Features in your map) and new features in that category will be added automatically.
        </p>
        <h3 id="category-filter" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Category Filter</h3>
        <p className="mb-8">
          Inside the Categories section, you can also define a custom color and/or a custom icon for each category. This can be used to display in the Category Filter, which presents a list to users that they can use to toggle on/off different features on your map based on the categories you've given them.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/map-categories.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/aXqgtgizz7U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
      </div>

      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/maps#filters-lists">
          <div>
            <div className="text-normal font-medium mb-1">Map Filters & Lists</div>
            <div className="text-sm text-gray-600">There's a ton of filters and lists available in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/advanced-category-filter">
          <div>
            <div className="text-normal font-medium mb-1">Advanced Category Filters</div>
            <div className="text-sm text-gray-600">Advanced Category Filters use custom syntax to add more options and filterable properties.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
