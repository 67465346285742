import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Maps</h2>
        <p className="mb-8">
          This section of the documentation covers everything to do with the "Map" post type, which is where you create the actual map that users will see on the front end of your site.
        </p>
        <p className="mb-8">
          Maps can be made with MapLibre, Mapbox, or Google Maps. There are some subtle differences in functionality across these systems. At the core, all contain the different geography features (Locations, Lines, Polygons) that you create elsewhere, numerous controls, and differing map styles and tiles.
        </p>
        <p className="mb-8">
          At the top of the map edit screen, you'll see a live preview of your map, which updates as you change the settings.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/map-changes.PNG').default} width="768" height="390" alt="Docs 01" />
        </figure>
        <h3 id="setup" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Setup</h3>
        <p className="mb-8">
          You can choose a <strong>Map Provider</strong>. This is the only setting that requires an "Update" of the post and reload in order to change.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>You need to enter a <a className="text-blue-500" href="https://account.mapbox.com/access-tokens">Mapbox Access Token</a> in order for the "Mapbox" option to work! If you're using Google Maps, <a href="https://developers.google.com/maps/documentation/javascript/get-api-key">you'll need an API key</a>.</span>
        </p>
        <p className="mb-8">
          You can set an <strong>access token</strong> for this map specifically, or, if you've entered a Default Mapbox Access Token in your settings, it will automatically appear here.
        </p>
        <p className="mb-8">
          Then comes choosing <strong>Map Styles</strong>, or the way the map looks. Different options will appear by default in the dropdown, depending on if you've entered a Mapbox token or not. We include some free options for MapLibre, and if you have an access token, Mapbox's default styles are provided as well. You can also enter a <strong>custom style ID</strong> if you are using Mapbox and you've designed your own custom style.
        </p>
        <p className="mb-8">
          If you have a custom JSON from a customized Mapbox style, or from another site like <a className="text-blue-500" href="https://www.maptiler.com/">Maptiler</a>, you can paste it into the Custom Style JSON textarea.
        </p>
        <p className="mb-8">
          If you're using Mapbox, you can choose a <strong>projection</strong> that's not the normal Mercator one, and opt to turn on <strong>3D terrain</strong> if you want to do that!
        </p>
        <h4 className="h4 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>View</h4>
        <p className="mb-8">
          The View section allows you to set how the map loads for users. You can set <strong>fit to features</strong>, which will zoom in to fit whatever features you place on the map, no matter the user's screen size. Or, you can specify a <strong>manual center and zoom</strong>.
        </p>
        <h4 className="h4 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Layout</h4>
        <p className="mb-8">
          In Layout, set the <strong>height</strong> and <strong>width</strong> of your map, along with the units (pixels or percentage) you want to use.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Note that setting 100% height doesn't usually work well. Instead, set a large pixel height that you think will fit most user's screens.</span>
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/map-setup.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h3 id="features" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Features</h3>
        <p className="mb-8">
          In the Features section, you can add and remove different geographies that you've created in the Locations, Lines, and Polygons sections. Click and select from the dropdown, or search for the title you've given to the different shapes.
        </p>
        <p className="mb-8">
          If you have assigned shapes to categories, you can add them en masse to a map using that category.
        </p>
        <p className="mb-8">
          You can also add <strong>clustering</strong> for Circle and for label & Icon type Locations.
        </p>
        <h3 id="interactivity" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Interactivity</h3>
        <p className="mb-8">
          Interactivity is a simple section, which doesn't affect the map preview but will affect behaviour for users. Turn on/off <strong>zooming on scroll</strong>, and on/off <strong>all interactivity</strong>.
        </p>
        <p className="mb-8">
          You can also choose whether you want popups to open in a sidebar or in a normal popup.
        </p>
        <h3 id="controls" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Controls</h3>
        <p className="mb-8">
          With Controls, you can turn off and on, and customize, different buttons that appear on the map that give users various abilities. You can set the <strong>position</strong> of any of these controls once they are turned on.
        </p>
        <p className="mb-8">
          The <strong>zoom control</strong> adds a +/- button, along with a small compass that allows users to quickly revert to north if they've rotated the map.
        </p>
        <p className="mb-8">
          The <strong>geocoder control</strong> allows users to search for real-world locations and zoom to them quickly on selection. When you enable this, you can also restrict the results to specific countries, by using 2-letter country codes separated by commas.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>The geocoder requires a Mapbox Access Token to use for searchable real-world locations.</span>
        </p>
        <p className="mb-8">
          The <strong>3D control</strong> allows users quickly switch between a 2D (top down) and 3D (tilted) view of the map.
        </p>
        <p className="mb-8">
          The <strong>directions control</strong> allows users to input a start and end destination and to get directions in a default Mapbox layout. Once enabled, you can set <strong>units</strong> as imperial or metric, set <strong>default type</strong> as Driving Traffic, Driving, Cycling, or Walking, and set a <strong>placeholder text</strong> that will appear in the "From" input.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>The directions control requires a Mapbox Access Token to use.</span>
        </p>
        <p className="mb-8">
          The <strong>geolocation control</strong> will allow users to quickly find themselves on the map by pressing a button.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>The geolocation control requires your website to use HTTPS.</span>
        </p>
        <p className="mb-8">
          The <strong>fullscreen control</strong> adds the ability for users to expand the map to fullscreen.
        </p>
        <p className="mb-8">
          The <strong>scale control</strong> adds a small metric scale that responds to the zoom level of the users.
        </p>
        <h3 id="filters-lists" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Filters & Lists</h3>
        <p className="mb-8">
          Filters & Lists comprise a series of advanced controls that allow you to create checkboxes to turn off/on features, searchable lists of features, and dropdowns -- all meant to allow your users to more easily find your features on the map. These are often used to create a Store Locator!
        </p>
        <h4 className="h4 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Custom Search Filter</h4>
        <p className="mb-8">
          The Custom Search Filter will give the user an input box to type into, and using their text, it will search through the post titles and popup text you've added in order to present clickable results that will zoom to the relevant feature.
        </p>
        <p className="mb-8">
          If you want, you can include a geocoder with this -- meaning that users will be able to search both the features you've added <em>and</em> real-world locations together. Your features will always appear first in the list if any match is found.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/search-filter.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h4 className="h4 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Filter Dropdown</h4>
        <p className="mb-8">
          The Filter Dropdown is a simple list of the features on your map, through which users can scroll in order to find a specific feature. Use this if you don't have a huge number of features on your map.
        </p>
        <h4 className="h4 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Category Filter</h4>
        <p className="mb-8">
          The Category Filter creates a toggleable list of Categories that you have associated with your Locations, Lines, and Polygons added to the current map. These are presented in a list, and include parent and child relationships. Users can toggle checkboxes on or off, thereby adding and removing different features from the map. You can initialize features as on or off depending what you require.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/aXqgtgizz7U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/category-filter.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          You can choose to use a <strong>custom color</strong> defined in the Categories next to the name of the category, a <strong>custom icon</strong>, or just to use the name of the category by itself.
        </p>
        <p className="mb-8">
          You can also choose to have a <strong>checkbox</strong> or a <strong>toggle</strong> display.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>See the Categories section of the Documentation for more information on customizing Categories.</span>
        </p>
        <h4 className="h4 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>List</h4>
        <p className="mb-8">
          The List is an interface that will present locations to your users as a list on your map. This is perfect for creating a Store Locator.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/list-with-geocoder.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          If you want to include a location search on top of your list, use the Geocoder Control in the "Controls" section, and it will interact naturally with the List.
        </p>
        <p className="mb-8">
          You can choose to <strong>sort by distance</strong>, which will change the order of results based on the current map center as the user moves around the map. You can also choose to <strong>show this distance</strong>, and can choose imperial or metric <strong>units</strong>.
        </p>
        <p className="mb-8">
          If you have a lot of features, you can also choose how many to display on each <strong>page</strong> of results.
        </p>
        <h3 id="custom-scripts" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Custom Scripts</h3>
        <p className="mb-8">
          See more on custom scripts in our <a className="text-blue-500" href="/documentation/developer#custom-scripts">Developer</a> section.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/developer">
          <div>
            <div className="text-normal font-medium mb-1">Mapster Developer Documentation</div>
            <div className="text-sm text-gray-600">Learn more about special functionality meant for developers.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/advanced-category-filter">
          <div>
            <div className="text-normal font-medium mb-1">Advanced Category Filters</div>
            <div className="text-sm text-gray-600">Advanced Category Filters use custom syntax to add more options and filterable properties.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
