import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Settings</h2>
        <p className="mb-8">
          This is about the general settings for the plugin, found in the Settings section under the Maps menu item.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/add-api-key.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          You can enter a <strong>default Mapbox access token</strong>, which can then be used across the site any time you create a new map.
        </p>
        <p className="mb-8">
          By default, the <strong>Gutenberg editor</strong> is turned off for Mapster WP Maps Maps, Locations, Lines, Polygons, and Popup Templates -- but you can turn it back on if you wish!
        </p>
        <p className="mb-8">
          Finally, you can choose whether or not you want different types of posts to be <strong>publically visible</strong> by users. By default, all the different geographies you create get their own pages, but you might prefer if they only appeared in your map and didn't link to a separate page. Turn this off/on as you desire here.
        </p>
        <h3 id="import-geo-file" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Import Geographic File</h3>
        <p className="mb-8">
          You can import GeoJSON, shapefiles, GPX, and KML files. If you need more types added, please get in touch with us and we'll add them.
        </p>
        <p className="mb-8">
          The version of the importer in the basic plugin can handle about 100 features at a time before having some errors occuring. If you <a className="text-blue-500" href="/pro">download the Pro version</a> you'll be able to import as many features as you like, and style them conditionally as well.
        </p>
        <p className="mb-8">
          When importing, we'll scan your feature and show you some details so you can see if things look right. We'll alert you to any potential issues in your data, and we have even built in a small reprojection tool to help you out if your geo file isn't meant for web maps.
        </p>
        <p className="mb-8">
          <strong>To use the reprojection tool</strong>, you'll first need to find out what projection your original file is in. You can go to <a className="text-blue-500" href="https://epsg.io/">https://epsg.io/</a> once you have an idea. When you have a specific projection page on EPSG.io, you need to get the Proj.4 projection string.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/epsg-proj4-string.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          For example, if my projection is "NAD83 / UTM zone 12N", then my EPSG page is <a className="text-blue-500" href="https://epsg.io/26912">https://epsg.io/26912</a>. If I scroll to the bottom of this page, there is an "Export" section, where one of the options on the left is "PROJ.4". If I click on that, I get the string "+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs
". This is what I should enter into the "Proj.4 string" input in the Mapster WP Maps Importer.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/try-reprojecting.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          A new file will be downloaded to your computer once you hit the Try Reprojecting button. You can verify if this is now the right projection by going to <a className="text-blue-500" href="https://geojson.io">geoJSON.io</a> and dragging your file. If everything looks right, you're in business!
        </p>
        <h3 id="import-gl-js" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Import GL JS</h3>
        <p className="mb-8">
          You can import from our old plugin, WP Mapbox GL JS. See the video below for a walkthrough!
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ToFCEjDI9Qk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <p>Get in touch if something's not working.</p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro/advanced-import">
          <div>
            <div className="text-normal font-medium mb-1">Advanced Importer</div>
            <div className="text-sm text-gray-600">Pro importing allows you to style and set categories during importing, and import huge geographic files without an issue.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Pro</div>
            <div className="text-sm text-gray-600">Documentation for the Pro version of the plugin.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
