import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Store Locator</h2>
        <p className="mb-8">
          Store Locators are one of the more complex and useful pieces out there for everyday mapmaking. We've added a lot of features into this addon to help you get started making a pretty and functional Store Locator for your site.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro, first, go to Settings and turn on the Store Locator</strong>. Then proceed to the rest of the instructions.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/5yyfJFt5824" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="adding-locations" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Adding Locations</h3>
        <p className="mb-8">
          With the Store Locator, you add Locations the same way you normally would with any Mapster map. Add Locations by going to Maps {">"} Locations, and adding them one by one.
        </p>
        <p className="mb-8">
          There will be a new set of fields under the content in the Locations, called "Additional Details". You can turn this on to add more information about any given location. This includes entering an <strong>address</strong>, <strong>phone number</strong>, <strong>hours</strong>, and <strong>social media links</strong>.
        </p>
        <p className="mb-8">
          For every type of Geography Type, you can create it and edit it by drawing directly, or by uploading a pre-created geoJSON for that geography type.
        </p>
        <p className="mb-8">
          You can also enter a short description that will appear when a user clicks on a specific location.
        </p>
        <p className="mb-8">
          To make it easy for yourself, create a category and add all your Locations to that category, so you can add them easily and quickly to your map later on.
        </p>
        <h3 id="making-locator-map" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Making Locator Map</h3>
        <p className="mb-8">
          Once you've created your Locations, create a new map. Add your locations inside the Features tab by selecting the category you created to hold the locations, and all your locations should be added to the map.
        </p>
        <p className="mb-8">
          Now, to add the sidebar that allows users to search for locations, click on them, see hours, and more. Go to the Filter & List tab, and scroll down to List. Enable this, and then scroll down more and you'll see "Store Locator Options".
        </p>
        <p className="mb-8">
          Turn on "Enable" to turn on a special interface more appropriate for Store Locators. You can see an example in the screenshot below.
        </p>
        <p className="mb-8">
          You can also sort the hours you assign to locations by days rather than having them appear from the beginning to the end of the week. This means that, based on the current day, the hours for today will show up first in the store locater listings.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/store-locator-list.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/store-locator-single.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <h3 id="tips" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Tips</h3>
        <p className="mb-8">
          You may want a full-screen Store Locator. To do that, turn on the full screen replacement in your Map by going to Setup {">"} Layout, and selecting the Full Page and Ignore Container options.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/maps#filters-lists">
          <div>
            <div className="text-normal font-medium mb-1">Map Filters & Lists</div>
            <div className="text-sm text-gray-600">There's a ton of filters and lists available in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
