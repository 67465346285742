import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">&copy; 2021 Mapster Technology Inc. All rights reserved.</div>
          <div className="text-sm text-right text-gray-600 mr-4"><a href="https://docs.google.com/forms/d/e/1FAIpQLScN5BgC-kiOPofI_4a1CgbvF27tNRpWrdgm6vNGEEha4iRPmQ/viewform?usp=sf_link">Contact Us</a></div>

        </div>

      </div>
    </footer>
  );
}

export default Footer;
