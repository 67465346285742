import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: Advanced Category Filter</h2>
        <p className="mb-8">
          Category filters are a common and remarkably complex feature used on many maps to allow users to more easily comb through your data. We've bundled a lot more options into Pro for you to build fantastic filters.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro</strong>, more options will immediately appear in the Category Filter section of your maps.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/nlqeebcM0rU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="adding-locations" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Advanced Options</h3>
        <p className="mb-8">
          You can set <strong>Functionality</strong> to Inclusive or Exclusive. This applies to cases in which features may have more than one category or filterable property. Filters are <em>exclusive</em> when any of the properties or categories being turned off means the feature disappears. <em>Inclusive</em> means that all of the properties or categories must be turned off before the feature disappears.
        </p>
        <p className="mb-8">
          <strong>Independent children</strong> means that child categories will function independently of whether or not their parent category is turned on. For this, you'll want to make sure that your child categories don't have the parent category clicked on (or, use inclusive filtering).
        </p>
        <p className="mb-8">
          <strong>Initial Visibility</strong> allows you to start the map with all categories and properties being turned off or on.
        </p>
        <h3 id="additional-filters" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Additional Filters</h3>
        <p className="mb-8">
          <strong>Additional Filters</strong> make it so you can use properties from your geographic data (imported or added onto posts), or ACF data, to create additional filters.
        </p>
        <p className="mb-8">
          First, you'll need to make sure your features actually have the data. You can see properties from geographic data in any given feature under "Custom Properties", and ACF fields will show up in the appropriate post types.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/custom-properties.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          Next, you'll use some special syntax to tell the Category Filter which properties to include under which headers. The syntax looks like this:
          <br /><br />
          <pre>
            {"property_name : Label\n\racf_property_name : Label 2"}
          </pre><br />
          If you are using a Custom Property, enter the property name only (no acf_ required) and then the label you want to show for that property heading, separated by a colon.
        </p>
        <p className="mb-8">
          Unlike the Advanced Popup, you don't need to get the field ID from ACF, and you don't need to use curly braces. Just enter the "name" of the ACF field, or the name of the property. This feature will run into problems if you have a property and an ACF field with the same name, though.
        </p>
        <p className="mb-8">
          If you've added these correctly, they will now show up alongside your categories in the Category Filter, and will integrate completely with the Inclusive and Exclusive filtering.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/categories">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Categories</div>
            <div className="text-sm text-gray-600">Learn more about creating categories in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/maps#filters-lists">
          <div>
            <div className="text-normal font-medium mb-1">Map Filters & Lists</div>
            <div className="text-sm text-gray-600">There's a ton of filters and lists available in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
