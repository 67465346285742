import React from 'react';
import { Link } from 'react-router-dom';

function DocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Welcome to the WP Maps Docs.</h2>
        <p className="mb-8">
          We're trying to make your mapmaking life a little easier, and we hope these docs help you out. Click around or search for what you need, and if you can't find it, <Link to="/support" className="text-gray-900 underline"><strong>get in touch with us</strong></Link>.
        </p>
        <a href="/documentation/pro-introduction" className="btn-sm text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">If you're looking for documentation for Pro features, go here.</a><br />
        <h3 id="installation" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Installation</h3>
        <p className="mb-8">
          Install like any other Wordpress plugin. Download the zip and upload to Plugins, or install using the Wordpress Directory in your Admin panel.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/O6c-0JOo6-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="overview" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Overview</h3>
        <p className="mb-8">
          Mapster WP Maps is a mapmaking tool that tries to make things straightforward for you. You can create geographies to use across multiple maps, style popups in totally customizable ways, create and use custom base maps, and set up category filters and more.
        </p>
        <p className="mb-8">
          We provide a free, no-API mapping service (<a className="text-blue-500" href="https://maplibre.org/" target="_blank">MapLibre</a>) as well as giving you access to the powerful <a className="text-blue-500" href="https://www.mapbox.com/mapbox-gljs" target="_blank">Mapbox GL JS library</a> in its latest version. We also integrate the <a className="text-blue-500" href="https://developers.google.com/maps">Google Maps API</a>.
        </p>
        <h4 className="h4 text-gray-900 mb-4">Your First Map</h4>
        <p className="mb-8">
          You can create a map without any geographies, but you probably want to at least show a point or an area on your map. To create a geography, select a Location (point), a Line, or a Polygon from the left hand side under "Maps" in your Wordpress menu.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/first-map.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          For the sake of simplicity, let's create a single Location for this map. Locations come in a few flavors: circles, markers, and labels/icons. We'll just create a simple Marker. Click on Locations and you'll see the screen below.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/first-location.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          Click to add the marker to the map, or just search an address anywhere on Earth in the search bar. Your marker will appear. Make sure you give a title to this post so you can find it later. You can adjust some settings below the map, such as color, opacity, etc, and then save the Location post.
        </p>
        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Learn more about <a className="text-blue-600 hover:underline" href="#0">Creating Locations</a>.</span>
        </p>
        <p className="mb-8">
          Now, you'll have to create a map to show this location on. Click on Maps on the left and add a new Map.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/find-maps.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          To keep it simple, let's just keep all the settings as default. Go to the "Features" tab under the map, and click on the Locations input. There, you'll see the title of your marker Location appear. Once you click it, it'll appear instantly on the map.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/add-location.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p className="mb-8">
          Now publish your map, and either visit the page for this map by viewing the post, or embed it in another post or width using the shortcode on the Maps page! Presto, your first map!
        </p>
        <h3 id="tokens" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Tokens & API Keys</h3>
        <p className="mb-8">
          To use Mapbox GL JS instead of MapLibre, you'll need to get an access token from Mapbox. <a className="text-blue-500" href="https://docs.mapbox.com/help/getting-started/access-tokens/">Read more about that here</a>. This access token is also needed for certain controls, specifically geocoders.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/add-api-key.PNG').default} width="80%" alt="Docs 01" />
        </figure>
        <p>
          Once you have the access token or API key that you need, enter it into the Settings screen, or into a single Map if you don't want to set a default settings token for the whole plugin at once.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/pro">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Pro</div>
            <div className="text-sm text-gray-600">Documentation for the Pro version of the plugin.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default DocumentationContent;
