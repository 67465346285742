import React from 'react';
import { Link } from 'react-router-dom';

function ProDocumentationContent() {
  return (
    <div className="md:flex-grow">

      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4">Pro: User Submission</h2>
        <p className="mb-8">
          A great way to enhance content on your site and increase user engagement is to allow users to submit and edit points and point information.
        </p>
        <p className="mb-8">
          Our User Submission plugin not only allows users to create geographies, but allows you to require any other information you need from users. You can collect data from extra Advanced Custom Fields, set different permissions, and create easy search functions for users to find their posts again.
        </p>
        <p className="mb-8">
          <strong>When you've downloaded Pro, first, go to Settings and turn on User Submission</strong>. Then proceed to the rest of the instructions.
        </p>
        <figure className="mb-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/qpUKBHwuLnA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <h3 id="global-options" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Global Options</h3>
        <p className="mb-8">
          User Submission has options that are set at the global level (across the site) as well as for individual create/edit pages.
        </p>
        <p className="mb-8">
          For global permissions, you can set <strong>Submit Permissions</strong> to public (anyone can submit), private (logged in users only), or admin (logged in as administrators). This only applies to front-end creation.
        </p>
        <p className="mb-8">
          You can also set <strong>Edit Permissions</strong>: public (anyone can submit), private (logged in users only), admin (logged in as administrators only), post creator only (logged in), or none (no editing allowed). This only applies to front-end editing.
        </p>
        <p className="mb-8">
          You can choose which features are editable: only those created by users, or all features, including those created by admins.
        </p>
        <p className="mb-8">
          <strong>In order to use edit functionality</strong>, you must define a page template that you've created with the User Submission template. Otherwise Mapster WP Maps will not know where to send users to edit a feature.
        </p>
        <p className="mb-8">
          Finally, you can <strong>Notify Users</strong> by email by selecting users to notify whenever a feature is created from the front-end.
        </p>
        <h3 id="page-templates" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Page Template</h3>
        <p className="mb-8">
          For submission of features, you must create a Page Template using the "Mapster User Submission" template -- this will be the link you provide to users in order for them to submit posts, and also is what you'll enter to define the "edit" page in the global options above.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/user-submission-template.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          Add a title and a description with instructions, if you like. Then scroll down to view the Page Template options.
        </p>
        <p className="mb-8">
          You can set an initial <strong>View</strong> consisting of a latitude, longitude, and zoom level, so users are oriented on the right place when they open the Submission page.
        </p>
        <p className="mb-8">
          Set the <strong>Feature Type</strong> that the user will create -- a Location (Marker), Location (Circle), Line, or Polygon.
        </p>
        <p className="mb-8">
          If you are creating a Location (Marker or Circle), a <strong>Geocoder</strong> will be automatically included. The default free geocoder is not the greatest, so you may want to use a Mapbox geocoder instead -- if so, you can select it here. You will need to enter an <strong>Access Token</strong> to use the Mapbox geocoder.
        </p>
        <p className="mb-8">
          A great little feature here is the ability to set a <strong>Post Template</strong>. When users submit a feature without this, it will be styled in the default manner by Mapster WP Maps. However, you can select another feature that you've already created that is styled the way you want, and all the user-created features will be duplicates (with a new geography) of that feature. This way you don't need to re-style anything manually.
        </p>
        <p className="mb-8">
          Set a <strong>Preset Category</strong> to have features automatically be added to a category, so they're easy to add to maps later (or get automatically added to existing maps instantly).
        </p>
        <p className="mb-8">
          Set the <strong>Post-Submission Text</strong> to tell users about how their submissions will be reviewed, or to alert them of anything you need.
        </p>
        <p className="mb-8">
          Finally, decide if you'd like submissions to be set to <strong>"Publish" immediately</strong>, or stay as Drafts until an administrator approves them manually.
        </p>
        <h3 id="custom-fields" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Custom Fields</h3>
        <p className="mb-8">
          You may want to collect more information from users than just a title, a geography, and a description. If so, you can easily integrate <a href="https://www.advancedcustomfields.com/" target="_blank">Advanced Custom Fields</a> into Mapster WP Maps and the fields will appear immediately in your frontend forms.
        </p>
        <p className="mb-8">
          Download and install ACF, and then create your fields. Assign them to appear on the feature type you created your Page Template for.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/acf-submission-fields.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <p className="mb-8">
          These fields will now appear as part of your front-end form, and will collect data from users. You can then user the data from these fields in other areas of your site -- output their data in a custom post template, or use them as part of the <a className="text-blue-500" href="/pro/advanced-category-filter">Advanced Category Filter</a>!
        </p>
        <h3 id="front-end-form" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Front End Form</h3>
        <p className="mb-8">
          If you've done all the steps above and set your information as you like, test your front-end form by opening up the Page you created with the Mapster User Submission Template.
        </p>
        <p className="mb-8">
          You may have to adjust styling a little with CSS to match your theme, or get in touch with us if anything seems really off.
        </p>
        <p className="mb-8">
          After submitting a post, users can either edit immediately, or can later visit that post to find an Edit button (if permissions allow them to edit). To help them find the post, see the Search Form section below.
        </p>
        <h3 id="search-form" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Search Form</h3>
        <p className="mb-8">
          If you want to make it easy for users to find features that they are trying to edit, or edit features they already created, you'll want to use the Search shortcode.
        </p>
        <p className="mb-8">
          Create a post, page, or widget (whereever you want the search input to appear), and use the shortcode
          <br /><br /><pre>[mapster_wp_map_search types="point,line,polygon"]</pre><br />
          You can remove any feature types that you don't want users to be able to find.
        </p>
        <p className="mb-8">
          This will create a search form where users can type in any term that exists in any titles or meta fields on the features, and will return a list of those features along with a link to go to the individual post.
        </p>
        <figure className="mb-8">
          <img className="w-full rounded" src={require('../images/documentation/search-form.PNG').default} width="768" height="390" alt="Docs 02" />
        </figure>
        <h3 id="tips" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Tips</h3>
        <p className="mb-8">
          This feature is complicated, but it can really help you create a powerful submission-based site. Let us know if any functionality you need seems to be missing!
        </p>
        <p className="mb-8">
          To have a truly crowdsourced map, turn Publish Immediately to on, and set a Preset Category to a category already being used in a front-end map. Then user posts will show up instantly!
        </p>
        <p className="mb-8">
          Combining the ACF custom fields with our <a className="text-blue-500" href="/pro/advanced-popups#popup-syntax">Custom Field Popup Syntax</a> can really superpower your site, too.
        </p>
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/documentation/categories">
          <div>
            <div className="text-normal font-medium mb-1">Mapster WP Maps Categories</div>
            <div className="text-sm text-gray-600">Learn more about creating categories in Mapster WP Maps.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>
  );
}

export default ProDocumentationContent;
