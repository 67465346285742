import React from 'react';
import Accordion from '../utils/Accordion';

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">Questions & Answers</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="How much does it cost?">
              <a className="text-blue-500" href="https://checkout.freemius.com/mode/dialog/plugin/10260/plan/17314/?show_monthly_switch=true">Click here</a> to see the latest cost.
            </Accordion>
            <Accordion title="What support do you offer?">
              We will make sure anything that we've advertised is working and assess any weird broken issues you face in your site to help get things working as expected. Aside from that, we're always open to new ideas. <a className="text-blue-600" href="https://docs.google.com/forms/d/e/1FAIpQLScN5BgC-kiOPofI_4a1CgbvF27tNRpWrdgm6vNGEEha4iRPmQ/viewform?usp=sf_link" target="_blank">Get in touch with us here</a>.
            </Accordion>
            <Accordion title="What is the difference between the Free and Pro versions?" active>
              <p style={{marginBottom: 10}}>The free version is meant to fully cover the needs of about 80% of users, with the Pro version having more advanced features for the other 20% of power users.</p>
              <p>To that end, we've made sure not to artificially block anything that basic users might need; instead, we just include more advanced and complex features in Pro. You can take a look at our <a className="text-blue-600" href="https://wpmaps-docs.mapster.me/pro-features/pro-maps" target="_blank">Documentation</a> to see extensive lists of features.</p>
            </Accordion>
            <Accordion title="Where do I download the Pro version?">
              Once you <a className="text-blue-500" href="https://checkout.freemius.com/mode/dialog/plugin/10260/plan/17314/?show_monthly_switch=true">purchase the Pro version</a>, you'll be able to replace the free version on your site and everything will go smoothly from there!
            </Accordion>
            <Accordion title="Is there a refund policy?">
              Absolutely. You can get a 100% refund in the first two weeks for any reason at all.
            </Accordion>
            <span className="block border-t border-gray-200" aria-hidden="true"></span>
          </ul >

        </div >
      </div >
    </section >
  );
}

export default Faqs;
